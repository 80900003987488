import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import gettext from 'airborne/gettext';
import Button from 'midoffice/components/Button';
import Modal from 'react-bootstrap/Modal';

import searchCars from 'airborne/store/modules/search_cars/actions/search';

@connect(null, {searchCars})
export default class CarsDestinationSearchRefreshModal extends React.Component {
    static propTypes = {
        searchCars: PropTypes.func,
        show: PropTypes.bool,
    };

    handleRefresh = () => {
        const {searchCars} = this.props;
        searchCars(true);
    }

    render() {
        const {show} = this.props;

        return (
            <Modal show={show} backdrop="static">
                <div className="info-modal info-modal-warning">
                    <Modal.Body>
                        <div className="modal-content__box modal-content__box--inner-padding">
                            {gettext('Still looking for a car? We recommend refreshing your results to see up-to-date prices for your search.')}
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.handleRefresh} bsStyle="success">
                            {gettext('Refresh')}
                        </Button>
                    </Modal.Footer>
                </div>

            </Modal>
        );
    }
}
