import gettext from 'airborne/gettext';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import dataset from 'airborne/bookings2/helpers/dataset';
import getPages from 'airborne/search_cars/helpers/pager';

export default class Pager extends React.Component {
    static propTypes = {
        page: PropTypes.number.isRequired,
        totalPages: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    handleChange = (event)=> {
        event.preventDefault();

        const {page} = dataset(event.target);
        if (page) {
            this.props.onChange(Number(page));
            window.scrollTo(0, 0);
        }
    };

    renderPrevious(page) {
        const className = classnames('c-pagination__nav', {
            'c-pagination__nav--inactive': page === 1,
        });
        return <li className={className} data-page={page-1}>« {gettext('Previous')}</li>;
    }

    renderNext(page, total) {
        const className = classnames('c-pagination__nav', {
            'c-pagination__nav--inactive': page === total,
        });
        return <li className={className} data-page={page+1}>{gettext('Next')} »</li>;
    }

    renderPage(page, active, idx) {
        const className = classnames('c-pagination__page', {
            'c-pagination__page--active': active,
            'c-pagination__page--inactive': !Boolean(page),
        });
        return (
            <li key={idx} data-page={page} className={className}>
                {page || '...'}
            </li>
        );
    }

    renderPages(current, total) {
        const pagerSize = 9;
        const pages = getPages(current, total, pagerSize);

        return (
            <ul className="c-pagination" onClick={this.handleChange}>
                {this.renderPrevious(current)}
                {pages.map((page, idx) => this.renderPage(page, page === current, idx))}
                {this.renderNext(current, total)}
            </ul>
        );
    }

    render() {
        const {page, totalPages} = this.props;

        if (!totalPages || totalPages === 1) {
            return null;
        }

        return (
            <div className="c-pagination__wrapper">
                <ul className="c-pagination">
                    {this.renderPages(page, totalPages)}
                </ul>
            </div>
        );
    }
}
