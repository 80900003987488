import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {connect} from 'react-redux';

import {gettext, ngettext} from 'airborne/gettext';
import dataset from 'airborne/bookings2/helpers/dataset';
import {toggleSorter} from 'airborne/search2/helpers/sorters';

import {getOrder} from 'airborne/store/modules/search_cars/selectors';
import {sortRates} from 'airborne/store/modules/search_cars/actions/rates';


class Sorter extends React.Component {
    static propTypes = {
        field: PropTypes.string,
        className: PropTypes.string,
        selected: PropTypes.string,
        children: PropTypes.any,
    };

    render() {
        const {field, selected, className} = this.props;
        const disabled = !field;

        const cls = classnames('c-content__sort-bar__col', `c-content__sort-bar__col--${className}`, {
            'c-content__sort-bar__col--sort-asc': !disabled && `-${field}` === selected,
            'c-content__sort-bar__col--sort-desc': !disabled && field === selected,
            'c-content__sort-bar__col--disabled': disabled,
        });
        return (
            <div className={cls} data-field={field}>
                <span className="c-content__sort-bar__text" data-field={field}>
                    {this.props.children}
                </span>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {value: getOrder(state)};
}


@connect(mapStateToProps, {onChange: sortRates})
export default class Sorters extends React.Component {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    handleSort = (event)=> {
        const {value, onChange} = this.props;
        const {field} = dataset(event.target);

        if (field) {
            onChange(toggleSorter(field, value));
        }
    };

    render() {
        const {value} = this.props;

        return (
            <div className="c-content__sort-bar" onClick={this.handleSort}>
                <Sorter field="total" className="price" selected={value}>
                    {gettext('Price')}
                </Sorter>
                <Sorter field="car.type" className="info" selected={value}>
                    {ngettext('Car', 'Cars', 1)}
                </Sorter>
                <Sorter className="location" selected={value}>
                    {gettext('Pick-Up')}
                </Sorter>
                <Sorter className="location" selected={value}>
                    {gettext('Drop-Off')}
                </Sorter>
            </div>
        );
    }
}
