import {connect} from 'react-redux';
import {getPagination} from 'airborne/store/modules/search_cars/selectors';
import {paginateRates} from 'airborne/store/modules/search_cars/actions/rates';
import Pager from 'airborne/common/Pager';

const CarPager = connect(state => {
    const {page, totalPages} = getPagination(state);
    return {page, totalPages};
}, {onChange: paginateRates})(Pager);

export default CarPager;
