/* global module */
import React from 'react';
import {hot} from 'react-hot-loader';

import SearchContext from 'airborne/search2/SearchContext';

import DestinationView from './DestinationView';
import Bar from './Bar';
import Rates from './Rates';
import Filters from './Filters';
import WaitBooking from './WaitBooking';
import Pager from './Pager';
import Sorters from './Sorters';
import HomepageButton from '../search2/HomepageButton';
import {getAdvertisingStatus} from 'airborne/store/modules/homepage/selectors/homepage';

import '../../../midoffice/less/bootstrap-search.less';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getCarsErrorCode} from 'airborne/store/modules/search_cars/selectors';
import gettext from 'airborne/gettext';
import CarsMap from 'airborne/search_cars/map/CarsMap';
const EMPTY_RATES_ERROR_CODE = 1032000;

@hot(module)
@connect((state) => {
    return {
        advertisingStatus: getAdvertisingStatus(state),
        errorCode: getCarsErrorCode(state),
    };
})
export default class Search extends React.Component {
    static propTypes = {
        advertisingStatus: PropTypes.bool,
        errors: PropTypes.array,
        errorCode: PropTypes.number,
    };

    renderError() {
        return (<div style={{marginTop: '30px'}}>
            <div className="section-wrapper">
                <div className="section-wrapper__message">
                    <h4>{gettext('It appears that all rates were excluded due to configured rate exclusion rules. We apologize for the inconvenience. If you believe you received this message in error, please report it to customer support.')}</h4>
                </div>
            </div>
        </div>);
    }

    render() {
        const {advertisingStatus, errorCode} = this.props;
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

        const showErrorMessage = errorCode === EMPTY_RATES_ERROR_CODE;
        return (<div>
            <DestinationView />
            <div className="container clearfix">
                <div className="c-sidebar">
                    <HomepageButton/>
                    <SearchContext/>
                    {!isIE11 && <CarsMap/>}
                    <Filters/>
                </div>
                <div className="c-content">
                    <Bar/>
                    {advertisingStatus && <div className="c-content__inline-a">
                        <broadstreet-zone zone-id="76485" />
                    </div>}
                    {showErrorMessage ? this.renderError() :  <Sorters/>}
                    <Rates/>
                    <Pager/>
                    {advertisingStatus && <div className="c-content__inline-a c-content__inline-a--content-footer">
                        <div className="c-content__inline-a__item">
                            <broadstreet-zone zone-id="76502"/>
                        </div>
                        <div className="c-content__inline-a__item">
                            <broadstreet-zone zone-id="76503"/>
                        </div>
                    </div>}
                </div>
            </div>
            <WaitBooking />
        </div>);
    }
}
