import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

import document from 'airborne/document';


export default class OutsideClickHandler extends React.Component {
    static propTypes = {
        onOutsideClick: PropTypes.func.isRequired,
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClick, {capture: true});
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    saveWrapper = (ref)=> this.wrapperNode = ref; // eslint-disable-line immutable/no-mutation

    handleClick = (e)=> {
        const {onOutsideClick} = this.props;
        if (this.wrapperNode && !this.wrapperNode.contains(e.target)) {
            onOutsideClick(e);
        }
    };

    render() {
        return <div {...omit(this.props, 'onOutsideClick')} ref={this.saveWrapper} />;
    }
}
