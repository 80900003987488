import wrap from 'airborne/search2/LazyComponent';

function load() {
    return new Promise((resolve)=> {
        require.ensure([], ()=> {
            const loaded = require('./Map3.js');
            resolve(loaded);
        }, 'hotelmap3');
    });
}

const Map = wrap(load);
export default Map;
