import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';

import {format as formatPrice} from 'airborne/utils/price';
import Range from 'rc-slider/lib/Range';
import gettext from 'airborne/gettext';
import {toUSD, fromUSD} from 'airborne/search2/helpers/price';
import {isHotels as isHotelsSelector} from 'airborne/store/modules/homepage/selectors/product';


function finite(value, fallback) {
    return (value === Infinity || value === -Infinity) ? fallback : value;
}


export const mapStateProps = createSelector([
    isHotelsSelector,
    (_, {currency})=> currency,
    (_, {prices})=> prices,
    (_, {value})=> value,
    (_, {onChange})=> onChange,
], (isHotels, currency, prices, value, onChange)=> ({
    prices: {
        min: isHotels ? fromUSD(prices.min, currency) : prices.min,
        max: isHotels ? fromUSD(prices.max, currency) : prices.max,
        currency,
    },
    value: value ? {
        ...value,
        min: isHotels ? fromUSD(value.min, currency) : value.min,
        max: isHotels ? fromUSD(value.max, currency) : value.max,
    } : value,
    onChange(value) {
        value = {
            ...value,
            min: isHotels ? toUSD(value.min, currency) : value.min,
            max: isHotels ? toUSD(value.max, currency) : value.max,
        };
        return onChange(value);
    }
}));


@connect(mapStateProps)
export default class PriceSlider extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        prices: PropTypes.shape({
            currency: PropTypes.string,
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        value: PropTypes.shape({
            available: PropTypes.bool,
            isOnRequestHotel: PropTypes.bool,
            min: PropTypes.number,
            max: PropTypes.number,
        }),
        onChange: PropTypes.func.isRequired,
        onAfterChange: PropTypes.func,
        showAvailFilter: PropTypes.bool.isRequired,
        disabled: PropTypes.bool
    };


    handleChangeAvail = (event)=> {
        const {checked} = event.target;
        const {value} = this.props;
        this.props.onChange({...value, available: checked});
    };

    handleChangeOnRequest = (event)=> {
        const {checked} = event.target;
        const {value} = this.props;
        this.props.onChange({...value, isOnRequestHotel: checked});
    };

    handleChangeSlider = ([min, max])=> {
        const {value} = this.props;
        const newValue = {...value, min, max};
        this.props.onChange(newValue);
    };

    render() {
        const {
            value: {available=false, isOnRequestHotel=false, ...value}={},
            prices: {currency, ...prices},
            showAvailFilter,
            onAfterChange,
            disabled
        } = this.props;

        const min = Math.max(value.min || prices.min, prices.min);
        const max = Math.min(value.max || prices.max, prices.max);
        const finiteMin = !isFinite(min) ? 0 : min;
        const finiteMax = !isFinite(max) ? 0 : max;
        const isDisabled = disabled || finite(prices.min) === finite(prices.max);
        return (<div className="slider">
            <div className="slider__info">
                <div className="slider__value slider__value--left">
                    {currency && finite(min) && formatPrice(min, currency)}
                </div>
                <div className="slider__value slider__value--right">
                    {currency && finite(max) && formatPrice(max, currency)}
                </div>
            </div>

            <div className="slider__control">
                <Range
                    allowCross={false}
                    disabled={isDisabled}
                    step={0.01}
                    key={isDisabled ? finiteMin : null}
                    value={[Math.round(finiteMin), Math.round(finiteMax)]}
                    min={Math.floor(prices.min)}
                    max={Math.ceil(prices.max)}
                    onChange={this.handleChangeSlider}
                    onAfterChange={onAfterChange} />
            </div>

            {showAvailFilter && <>
                <div className="slider__row">
                    <div className="checkbox checkbox-rev">
                        <label>
                            <input
                                type="checkbox"
                                checked={available}
                                onChange={this.handleChangeAvail} />
                            <span className="checkbox__icon"/>
                            {gettext('Show available hotels only')}
                        </label>
                    </div>
                </div>
                <div className="slider__row">
                    <div className="checkbox checkbox-rev">
                        <label>
                            <input
                                type="checkbox"
                                checked={isOnRequestHotel}
                                onChange={this.handleChangeOnRequest} />
                            <span className="checkbox__icon"/>
                            {gettext('Show on-request hotels')}
                        </label>
                    </div>
                </div>
            </>}
        </div>);
    }
}
