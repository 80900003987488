import range from 'lodash/range';


export default function getPages(current, total, pagerSize) {
    if (total <= pagerSize) {
        // 1 2 3 4 5 6 7 8 9
        return range(1, total + 1);
    }
    else if (current <= Math.ceil(pagerSize / 2)) {
        // 1 2 3 _4_ 5 6 7 ... 27
        // 1 2 3 4 _5_ 6 7 ... 27
        return [...range(1, pagerSize-1), null, total];
    }
    else if (current >= total - Math.floor(pagerSize / 2)) {
        // 1 ... 21 22 _23_ 24 25 26 27
        // 1 ... 21 22 23 _24_ 25 26 27
        return [1, null, ...range(total - pagerSize + 3, total + 1)];
    }
    else {
        // 1 ... 4 5 _6_ 7 8 ... 27
        // 1 ... 5 6 _7_ 8 9 ... 27
        // 1 ... 19 20 _21_ 22 23 ... 27
        // 1 ... 20 21 _22_ 23 24 ... 27
        let leftPad = Math.floor((pagerSize - 5) / 2);  // how many pages to display by left side of current
        let rightPad = Math.ceil((pagerSize - 5) / 2);  // how many pages to display by right side of current
        return [1, null, ...range(current - leftPad, current + rightPad + 1), null, total];
    }
}
