function normalize(field) {
    if (field.startsWith('-')) {
        return field.slice(1);
    }

    return field;
}

function revert(field) {
    // test => -test => -test
    // -test => --test -> test
    return `-${field}`.replace('--', '');
}

function equalFields(first, second) {
    return normalize(first) === normalize(second);
}

function getDefault(field, defaults={}) {
    field = normalize(field);
    const order = defaults[field] || 'ASC';

    return order === 'ASC' ? field : revert(field);
}

export function toggleSorter(field, current, defaults={}) {
    if (equalFields(current, field)) { return revert(current); }
    return getDefault(field, defaults);
};
